import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import OverlayContent from 'components/Header/OverlayContent';

const Wrapper = styled('div', { padding: '80px 16px 16px 16px' });

const EmptyBasket = ({ content }) => (
    <Wrapper>
        <OverlayContent content={content.basketEmptyContent} />
    </Wrapper>
);

EmptyBasket.propTypes = {
    content: PropTypes.object,
};

export default EmptyBasket;
